@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,600;1,400&display=swap');

$font-family-sans-serif: 'Alegreya', serif;

$blue: rgb(0, 96, 160);
$enable-rounded: false;
$success: rgb(234, 78, 78);

$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-border-color: rgb(204, 204, 204);
$input-focus-border-color: rgb(204, 204, 204);

$accordion-color:                         #000;
$accordion-bg:                            unset;
$accordion-border-width:                  0px;

$accordion-body-padding-y:                2rem;

$accordion-button-active-bg:              rgba(192, 172, 103, .3);
$accordion-button-active-color:           #000;
$accordion-icon-width:                    1.25rem;
$accordion-icon-color:                    rgba(192, 172, 103, .5);
$accordion-icon-transition:               transform .2s ease-in-out;
$accordion-icon-transform:                rotate(-180deg);

.accordion-button.collapsed::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border-top: .5px solid rgba(192, 172, 103, .5);
  width: 100%;
}

.modal-admin {
  font-family: system-ui;
  font-size: 0.8rem;
}

@import "~bootstrap/scss/bootstrap";
